// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.35s,
		transition:			0.2s,
		banner:				2.5s
	);

// Size.
	$size: (
		border-radius:		4px,
		element-height:		2.75em,
		element-margin:		2em,
		inner:				65em
	);

// Font.
	$font: (
		family:				('Source Sans Pro', Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300,
		weight-bold:		600,
		letter-spacing:		0.025em,
		letter-spacing-alt:	0.25em
	);

// original Palette.
	// $palette: (
	// 	bg:					#242943,
	// 	bg-alt:				#2a2f4a,
	// 	fg:					#ffffff,
	// 	fg-bold:			#ffffff,
	// 	fg-light:			rgba(244,244,255,0.2),
	// 	border:				rgba(212,212,255,0.1),
	// 	border-bg:			rgba(212,212,255,0.035),
	// 	highlight:			#9bf1ff,
	// 	accent1:			#6fc3df,
	// 	accent2:			#8d82c4,
	// 	accent3:			#ec8d81,
	// 	accent4:			#e7b788,
	// 	accent5:			#8ea9e8,
	// 	accent6:			#87c5a4
	// );

	// j5k Palette.
	$palette: (
		bg:					#000000,
		bg-alt:				#000000,
		fg:					#ffffff,
		fg-bold:			#ffffff,
		fg-light:			rgba(244,244,255,0.2),
		border:				rgba(104, 0, 0, 0.1),
		border-bg:			rgba(201, 188, 5, 0.035),
		highlight:			#f0ff23,
		accent1:			#850000,
		accent2:			#eefa46,
		accent3:			#1b7500,
		accent4:			#ccb802,
		accent5:			#0c4700,
		accent6:			#580000
	);
